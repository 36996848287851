<template>
	<div v-if="!declineEmailSuccess">
		<h1 class="mb-10 text-3xl font-semibold text-center">
			{{ t("decline_email_change") }}
		</h1>
		<div class="form-wrapper">
			<span
				v-show="apiError"
				class="form-wrapper__validation-error mb-3"
			>
				{{ apiError }}
			</span>

			<button
				v-show="!apiError"
				class="form-wrapper__submit uppercase"
				:disabled="apiLoader"
				@click="changeEmail"
			>
				{{ apiLoader ? t("loadingMessage") : t("decline") }}
			</button>
		</div>
	</div>
	<div
		class="p-5 text-center bg-hr-pink"
		v-else
	>
		<h1 class="mb-5 text-3xl text-center">
			{{ t("decline_email_success") }}
		</h1>
		<a
			:href="webLoginUrl"
			class="form-wrapper__link"
		>
			{{ t("back_to_login") }}
		</a>
	</div>
</template>

<script>
	import { useRoute } from "vue-router";
	import { useI18n } from "vue-i18n";
	import useApi from "@/composables/useApi";
	import { computed, ref } from "vue";
	import axios from "axios";

	export default {
		name: "DeclineEmail",
		setup() {
			const route = useRoute();
			const { t, te } = useI18n();
			const { handleApi } = useApi();
			const apiError = ref(null);
			const apiLoader = ref(false);
			const declineEmailSuccess = ref(false);
			const webLoginUrl = computed(() => {
				return `${process.env.VUE_APP_WEB_URL}/login`;
			});

			const changeEmail = async () => {
				let payload = {
					key: route.query.key,
				};
				apiLoader.value = true;
				let [response, declineEmailError] = await handleApi(
					axios.post(`${process.env.VUE_APP_AUTH_URL}/v1/users/decline-email-change`, payload)
				);
				if (declineEmailError)
					apiError.value = te(`apiErrors.${declineEmailError}`)
						? t(`apiErrors.${declineEmailError}`)
						: t(`apiErrors.ERR_INVALID_BINDING_STRUCTURE`);
				if (response) declineEmailSuccess.value = true;
				setTimeout(() => {
					apiLoader.value = false;
				}, 300);
			};

			return {
				t,
				apiLoader,
				apiError,
				webLoginUrl,
				declineEmailSuccess,
				changeEmail,
			};
		},
	};
</script>
